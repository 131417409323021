<template>
  <b-col :cols="lengthScreen.width < breakPoint ? 12 : 6">
    <div v-if="lengthScreen.width < breakPoint" class="mt-4 box-logo">
      <img :src="logo" alt="logo" height="50" />
    </div>

        <slot />
  </b-col>
</template>

<script>
import { reactive, defineComponent } from '@vue/composition-api'
import { BCol } from "bootstrap-vue";

import { caminhoLogo, breakPointScreen } from "@/plugins/contantes";
export default defineComponent({
  components: {
    BCol
  },
  setup() {

    const lengthScreen = reactive({
      width: 0,
      height: 0
    })

    function getLengthScreen() {
      lengthScreen.width = window.innerWidth;
      lengthScreen.height = window.innerHeight;
    }

    window.addEventListener('resize', getLengthScreen)
    getLengthScreen()

    return {
      lengthScreen,
      getLengthScreen
    }
  },
  computed: {
      logo () {
          return caminhoLogo()
      },
      breakPoint (){
        return breakPointScreen("md")
      }
  },
  destroyed() {
    window.addEventListener("resize", this.getLengthScreen);
  },
})
</script>

<style>
@media only screen and (max-width: 810px)  {
  .box-logo {
    height: 100px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>